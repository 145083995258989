.ar.checkout{
	display: flex;
	width: 100%;
	justify-content: center;
	.column{
		margin: 0 10px;
		width: 100%;
		max-width: 500px;
		&.small{
			max-width: 330px;
		}
		table.fr.table{
			margin: 0;
			tr td:last-child{
				text-align: right;
			}
			tr.totals td{
				$BORDER: 1px solid rgba(0,0,0,0.1);
				border-top: $BORDER;
				border-bottom: $BORDER;
				&:nth-child(2){
					color: brown;
					font-weight: bold;
					font-size: 16px;
				}
			}
		}
	}
	@media screen and (max-width: 700px){
		display: block;
		.column{
			margin: 0;
			padding: 5px 10px;
			max-width: 100%;
			&.small{
				max-width: 100%;
			}
		}
	}
}