.ar.event{
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 20px;
	margin-bottom: 20px;

	img{
		width: 100px;
		height: 100px;
		border-radius: 8px;
		box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
		border: 1px solid #dddddd;
	}
	.info{
		display: flex;
		justify-content: center;
		flex-direction: column;
		padding: 0 10px;
		.name{
			font-size: 24px;
			font-weight: bold;
			max-width: 300px;
			line-height: 26px;
			margin-bottom: 5px;
		}
		.venue{
			font-size: 16px;
			color: gray;
		}
		.city{
			color: gray;
		}
	}

	@media screen and (max-width: 450px){
		margin-bottom: 0;
		padding-top: 0 !important;
	}
}
.fr.selection.groupper{
	max-width: 500px;
	margin: auto;
	margin-bottom: 50px;

	$BACKGROUND_PRICE: #F7F7F7;
	$BACKGROUND_NAME: white;
	$BORDER: #e5e7eb;

	> .head{
		margin-bottom: 0;
		&.select .left{
			margin-right: 57px;
		}
		&:not(.select){
			.return.button{
				display: none;
			}
		}
	}

	&.seatmap{
		max-width: 700px;
		padding: 0;
		> .head{
			margin-bottom: 0;
		}
		> .head,.ar.section.label{
			margin-left: 0;
			margin-right: 0;
			width: 100%;
		}
		
		.ar.section.label.top{
			margin-top: 0;
			margin-bottom: 0;
		}
		> .actions{
			width: auto;
			margin: 0;
		}
	}
	.ar.tickets{
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		margin-bottom: 20px;
		.ticket{
			width: 40%;
			padding: 10px;
			margin: 5px;
			border-radius: 6px;
			background-color: rgba(0,0,0,0.04);
			.top{
				display: flex;
				align-items: center;
			}
			.section{
				flex-grow: 100;
				color: gray;
			}
			.info{
				font-weight: bold;
			}
			.price{
				text-align: right;
				color: #B12704;
				font-weight: bold;
				i.minus{
					color: gray;
				}
			}
		}
	}

	.ar.dropdown.label{
		text-align: center;
		margin-top: 10px;
		margin-bottom: 5px;
		font-weight: bold;
	}
	.ui.ticket-price.dropdown{
		margin: auto;
		display: block;
		max-width: 400px;
		margin-bottom: 10px;

		i.dropdown.icon{
			height: 100%;
			line-height: 20px;
		}
	}

	.canvas{
		width: 100%;
		height: 400px;
	}

	.ar.section.label{
		text-align: center;
		font-weight: bold;
		font-size: 16px;
		
		width: calc(100% + 40px);
		padding: 10px;
		background-color: rgba(0,0,0,0.05);
		margin-left: -20px;
		margin-bottom: 10px;
		border-top: 1px solid rgba(0,0,0,0.05);
		border-bottom: 1px solid rgba(0,0,0,0.05);
	}
	&.fitted{
		.ar.section.label{
			width: 100%;
			margin: 0;
			margin-bottom: 10px;
		}
	}

	.ar.sections .section{
		display: flex;
		margin-bottom: 5px;
		border: 1px solid #e5e7eb;
		border-radius: 6px;
		overflow: hidden;
		cursor: pointer;

		.price{
			font-weight: bold;
			background-color: $BACKGROUND_PRICE;
			padding: 10px;
			min-width: 110px;
			font-size: 16px;
			text-align: center;
			border-right: 1px solid #e5e7eb;
		}
		.name{
			padding: 10px;
			font-size: 16px;
			flex-grow: 100;
		}
		&:hover{
			.price{
				background-color: darken($BACKGROUND_PRICE, 2%);
			}
			.name{
				background-color: darken($BACKGROUND_NAME, 2%);
			}
		}
		&.placeholder{
			text-align: center;
			background-color: lighten($BACKGROUND_PRICE, 1%);
		}
		&.soldout{
			.price{
				background-color: #e7e7e7;
				color: gray;
				border-right: 1px solid #dadada;
			}
			.name{
				color: gray;
				background-color: #f5f5f5;
			}
		}
		&:last-child{
			margin-bottom: 0;
		}
	}

	.fr.tiny.return.button{
		display: block;
		margin: auto;
		margin-top: -5px;
		width: 150px;
		margin-bottom: 10px;
	}
	
	table.totals{
		text-align: right;
		margin: auto;
		margin-top: 20px;
		max-width: 500px;
		margin-bottom: 15px;
		width: 100%;
		tr td{
			text-align: right;
			&:first-child{
				font-weight: bold;
			}
			&:last-child{
				min-width: 100px;
				width: 1px;
				white-space: nowrap;
				padding-left: 20px;
			}
			> i{
				color: #b9b9b9;
			}
		}
		tr.subtotal td{
			font-size: 20px;
			font-weight: bold;
			&:last-child{
				color: #B12704;
			}
		}
	}

	.unavailable{
		display: flex;
		align-items: center;
		justify-content: center;
		height: 400px;
		padding-top: 15px;
	}
}

.ar.delivery,.ar.terms.checkbox,.fr.error.message{
	max-width: 500px;
	margin: auto;
}
.ar.terms.checkbox,.fr.error.message{
	margin-bottom: 20px;
}
.ar.delivery .method, .ar.checkbox{
	$BACKGROUND_PRICE: #F7F7F7;
	$BACKGROUND_NAME: white;
	$BORDER: #e5e7eb;
	$AREMA_COLOR: rgba(243,116,53,1);
	display: flex;
	width: 100%;
	font-size: 16px;
	font-weight: bold;
	height: 45px;
	border-radius: 6px;
	cursor: pointer;
	background-color: rgba(0,0,0,.01);
	margin-bottom: 5px;

	> .icon{
		width: 40px;
		padding-left: 15px;
		height: 45px;
		line-height: 43px;
		font-size: 22px;
		font-family: outline-icons;
		border-color: $BORDER;
		border-style: solid;
		border-width: 1px 0 1px 1px;
		margin: 0;
		border-top-left-radius: 6px;
		border-bottom-left-radius: 6px;
		&::before{
			content: "\f111";
		}
	}
	.name,.label{
		border-color: $BORDER;
		border-style: solid;
		border-width: 1px 1px 1px 0;
		flex-grow: 100;
		line-height: 43px;
		padding: 0 10px;
	}
	.cost{
		overflow: hidden;
		border-color: $BORDER;
		border-style: solid;
		border-width: 1px 1px 1px 0;
		min-width: 100px;
		text-align: center;
		line-height: 43px;
		background-color: $BACKGROUND_PRICE;
		padding: 0 10px;
		border-top-right-radius: 6px;
		border-bottom-right-radius: 6px;
	}

	&.active{
		.cost{
			background-color: $AREMA_COLOR;
			border-color: $AREMA_COLOR;
			color: white;
		}
		> .icon{
			color: $AREMA_COLOR;
			font-family: Icons;
			&::before{
				content: "\f058";
			}
		}
	}
}
.ar.checkbox{
	min-height: 45px;
	height: auto;
	align-items: center;
	.icon{
		min-height: 45px;
		height: auto;
		height: 45px;
		line-height: 43px;
		font-size: 20px;
	}
	.name,.label{
		min-height: 45px;
		height: auto;
		font-weight: normal;
		line-height: 14px;;
		font-size: 14px;
		border-width: 1px 1px 1px 0;
		border-top-right-radius: 6px;
		border-bottom-right-radius: 6px;

		display: flex;
		align-items: center;
	}
}

.ar.prices{
	$BACKGROUND: rgba(0,0,0,0.03);
	$PRICE_COLOR: rgba(243,116,53,1);
	$NAME_BORDER: #e5e7eb;

	.price{
		display: flex;
		margin-bottom: 8px;
		font-size: 16px;
		border-radius: 6px;
		overflow: hidden;
		background-color: $BACKGROUND;

	
		.cost{
			border: 1px solid $PRICE_COLOR;
			min-width: 80px;
			background-color: $PRICE_COLOR;
			color: white;
			text-align: center;
			font-weight: bold;
			border-top-left-radius: 6px;
			border-bottom-left-radius: 6px;
			line-height: 38px;
		}
		.name{
			border: 1px solid $NAME_BORDER;
			flex-grow: 100;
			line-height: 38px;
			padding: 0 10px;
			font-weight: bold;
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow-x: hidden;
		}
		.quantity{
			display: flex;
			.fr.button{
				min-width: 30px;
				width: 30px;
				padding: 0px;
				height: 40px;
				text-align: center;
				border-radius: 6px;
				> i{
					margin: 0;
					line-height: 38px;
				}
				&.minus{
					border-top-right-radius: 0;
					border-bottom-right-radius: 0;
				}
				&.plus{
					border-top-left-radius: 0;
					border-bottom-left-radius: 0;
				}
			}
			.amount{
				border: 1px solid $NAME_BORDER;
				padding: 10px;
				height: 40px;
				font-weight: bold;
				font-size: 20px;
				min-width: 50px;
				text-align: center;
				// background-color: rgba(0,0,0,0.05);
			}
		}
	}
	&.selectable{
		.price{
			cursor: pointer;
			&:hover{
				background-color: rgba(0,0,0,0.05);
				.cost{
					background-color: darken($PRICE_COLOR, 5);
					border-color: darken($PRICE_COLOR, 5);
				}
			}
		}
	}
}

.ar.captcha{
	display: flex;
	justify-content: flex-end;
	margin: auto;
	margin-top: 10px;
	max-width: 500px;
}

.g-recaptcha{
	display: flex;
	justify-content: center;
	margin-top: 20px;
}

.ar.checkout-contents{
	padding: 0 10px;

	.fr.queue.message{
		margin: -25px auto 15px auto;
		max-width: 500px;

		.contents{
			display: flex;
			align-items: center;
			i.icon{
				margin-right: 15px;
				margin-left: 5px;
				font-size: 48px;
			}
			.countdown{
				background-color: rgb(18, 34, 98);
				color: white;
				width: 110px;
				border-radius: 100px;
				font-size: 20px;
				padding: 8px;
				margin: auto;
				margin-bottom: 5px;
			}
			.content{
				flex-grow: 100;
				.fr.header{
					color: rgb(18, 34, 98);
					margin-bottom: 5px;
				}
			}
		}

		@media screen and (max-width: 500px){
			margin-top: 0px;
			border-radius: 0;
			.contents{
				i.icon{
					margin-left: 0;
				}
				.content{
					.fr.header{
						font-size: 20px;
					}
				}
			}
		}
	}

	@media screen and (max-width: 640px){
		padding: 0;
		.fr.groupper{
			border-radius: 0;
			.head{
				border-radius: 0;
			}
		}
	}
}

@media screen and (max-width: 640px){
	.ar.event{
		padding: 20px 10px;
	}
	.fr.selection.groupper{
		margin: 5px;
	}
}

@media screen and (max-width: 500px){
	.fr.selection.groupper.fitted{
		> .totals{
			padding: 0 10px;
		}
		.ar.delivery{
			padding: 0 10px;
		}
		.ar.terms.checkbox{
			padding: 0 10px;
		}
		.ar.captcha{
			padding: 0 10px;
		}
	}
}

@media screen and (max-width: 400px){
	.fr.selection.groupper:not(.fitted){
		padding: 10px;
		.head{
			margin: -11px;
			margin-bottom: 0px;
		}
		.actions{
			margin: -10px;
			margin-top: 10px;
			width: calc(100% + 20px);
		}
		.ar.section.label{
			margin-left: -10px;
			margin-top: 0px;
			width: calc(100% + 20px)
		}
	}
	.ar.captcha{
		justify-content: center;
	}
}