body > .body{
	padding: 10px;
	> .fr.groupper{
		max-width: 500px;
		margin: auto;
	}
}

.ar.code{
	display: block;
	margin: auto;
	width: 150px;
	height: 150px;
	background-color: white;
	padding: 5px;
	margin-top: 10px;
	border-radius: 6px;
	border: 1px solid #E8E8E8;
}

.fr.main.groupper{
	margin-top: 40px !important;
	p{
		text-align: center;
	}

	i.main.icon{
		margin: auto;
		display: block;
		font-size: 80px;
		line-height: 125px;
		margin-top: -50px;
		background-color: #1EAA6F;
		color: white;
		// border: 10px solid #f5f5f5;
		border-radius: 140px;
		width: 120px;
		height: 120px;

		&.pending{
			background-color: #ffbf26;
		}
	}
	> .fr.centered.header{
		margin-top: 10px;
	}
	> .actions{
		text-align: center;
		.fr.button{
			height: 50px;
			font-size: 20px;
			line-height: 29px;
			width: 280px;
		}
	}
	.fr.stats .stat{
		padding-top: 5px;
		padding-bottom: 0;
	}
}
.fr.hash.input{
	text-align: center;
	margin-bottom: 10px;
	label{
		margin-bottom: 0;
	}
	input{
		margin: auto;
		max-width: 300px;
		text-align: center;
		font-size: 24px;
		font-weight: bold;
		font-family: 'Roboto Mono', monospace;
		padding: 5px;
	}
}
.fr.tickets.groupper table.fr.table{
	margin-top: 0;
	tr{
		td{
			padding-top: 0;
		}
		&:first-child{
			td{
				padding-top: 10px;
			}
		}
		td:first-child{
			text-align: right;
		}
		td:last-child{
			text-align: right;
			font-weight: bold;
		}
	}
}
.fr.tickets.groupper,.fr.instructions.groupper{
	margin-top: 10px !important;
	max-width: 500px;
}
.fr.expiration.stat{
	padding-bottom: 0;
	.label{
		color: #bb2828
	}
}

.fr.tickets.items{
	.item{
		display: flex;
		img{
			width: 80px;
			height: 80px;
			display: block;
			border-radius: 8px;
			border: 1px solid rgba(0,0,0,0.1)
		}
		.info{
			padding: 0 10px;
			font-size: 14px;
			line-height: 18px;

			.event{
				font-size: 16px;
				line-height: 20px;
				font-weight: bold;
			}
			.meta{
				color: gray;
			}
			.cost{
				font-weight: bold;
				color: #c51818;
			}
			.paid{
				font-weight: bold;
				color: green;
			}
		}
	}
	&.small .item img{
		width: 60px;
		height: 60px;
	}
}

@media screen and (max-width: 450px){
	.fr.main.groupper{
		text-align: center;
		.fr.stats .stat .value{
			font-size: 40px;
			line-height: 40px;
		}
	}
}
